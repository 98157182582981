<template>
    <div class="c3-form-field-group c3-form-field-group-opening-time">
        <div class="c3-form-field-has-opening-time">
            <c3-form-switch v-model="localHasOpeningTimes">
                <template slot="labelBefore">{{ $t('pages.customer.registration.openingTimes.label') }}</template>
            </c3-form-switch>
        </div>
        <div class="c3-form-field-wrapper" :class="{'c3-form-field-wrapper--collapsed':!hasOpeningTimes}">
            <c3-opening-time-day :label="dayLabel('monday')" v-model="openingTimesMonday"/>
            <c3-opening-time-day :label="dayLabel('tuesday')" v-model="openingTimesTuesday" :previousDay="openingTimesMonday"/>
            <c3-opening-time-day :label="dayLabel('wednesday')" v-model="openingTimesWednesday" :previousDay="openingTimesTuesday"/>
            <c3-opening-time-day :label="dayLabel('thursday')" v-model="openingTimesThursday" :previousDay="openingTimesWednesday"/>
            <c3-opening-time-day :label="dayLabel('friday')" v-model="openingTimesFriday" :previousDay="openingTimesThursday"/>
            <c3-opening-time-day :label="dayLabel('saturday')" v-model="openingTimesSaturday" :previousDay="openingTimesFriday"/>
            <c3-opening-time-day :label="dayLabel('sunday')" v-model="openingTimesSunday" :previousDay="openingTimesSaturday"/>
        </div>
    </div>
</template>

<script>
    import {C3Object} from '@/domain/utility/object';
    import C3OpeningTimeDay from '@/components/form/fields/customer/OpeningTimeDay';

    function createComputedOpeningTimesDayObject(day) {
        return {
            get() {
                return this.localOpeningTimes[day];
            },
            set(value) {
                // Nested property doesn't call setter, so we trigger update:openingTimes here
                this.$set(this.localOpeningTimes, day, value);
                this.$emit('update:openingTimes', this.localOpeningTimes);
            }
        }
    }

    export default {
        components: {
            C3OpeningTimeDay
        },
        props: {
            hasOpeningTimes: {
                type: Boolean,
                required: true
            },
            openingTimes: {
                validator: prop => typeof prop === 'object' || prop === null,
                required: true
            },
        },
        data() {
            return {}
        },
        computed: {
            localHasOpeningTimes: {
                get() {
                    return this.hasOpeningTimes;
                },
                set(value) {
                    this.$emit('update:hasOpeningTimes', value);
                }
            },
            localOpeningTimes: {
                get() {
                    let weekdays = {};
                    DATE.WEEKDAYS.forEach(day => {
                        if (this.openingTimes && this.openingTimes[day]) {
                            weekdays[day] = this.openingTimes[day]
                        } else {
                            weekdays[day] = null;
                        }
                    });
                    return C3Object.deepClone(weekdays, true);
                },
                set(value) {
                    this.$emit('update:openingTimes', value);
                }
            },
            openingTimesMonday: createComputedOpeningTimesDayObject('monday'),
            openingTimesTuesday: createComputedOpeningTimesDayObject('tuesday'),
            openingTimesWednesday: createComputedOpeningTimesDayObject('wednesday'),
            openingTimesThursday: createComputedOpeningTimesDayObject('thursday'),
            openingTimesFriday: createComputedOpeningTimesDayObject('friday'),
            openingTimesSaturday: createComputedOpeningTimesDayObject('saturday'),
            openingTimesSunday: createComputedOpeningTimesDayObject('sunday'),
        },
        methods: {
            dayLabel(day) {
                return this.$t('weekdays.long.' + day);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .c3-form-field-has-opening-time {
        border-bottom: 2px solid #FFFFFF;
        padding-bottom: 25px;
    }

    .c3-form-field-wrapper {
        overflow: hidden;
        max-height: 1550px;
        transition: max-height 0.4s;
    }

    .c3-form-field-wrapper--collapsed {
        max-height: 0;
    }
</style>
