<template>
    <c3-profile-form-page :title="$t('pages.customer.profile.stores.title')" :subtitle="subtitle">
        <c3-form @submit="submit">
            <c3-form-input :label="$t('customer.store.address')" v-model.trim="$v.store.address.street.$model" :validator="$v.store.address.street"/>
            <c3-form-input :label="$t('customer.store.zip')" v-model.trim="$v.store.address.zip.$model" :validator="$v.store.address.zip"/>
            <c3-form-input :label="$t('customer.store.city')" v-model.trim="$v.store.address.city.$model" :validator="$v.store.address.city"/>
            <c3-form-select :label="$t('customer.country')" v-model="$v.store.address.country.$model" :options="countryOptions" :placeholder="$t('label.pleaseSelectOption')"/>
            <c3-form-input :label="$t('customer.store.phone.label')"
                           type="tel"
                           v-model.trim="$v.store.phone.$model"
                           :validator="$v.store.phone"
                           :description="$t('customer.store.phone.description')"
            />
            <c3-form-input :label="$t('customer.store.email')" type="email" v-model.trim="$v.store.email.$model" :validator="$v.store.email"/>
            <c3-customer-opening-time :hasOpeningTimes.sync="store.hasOpeningTimes" :openingTimes.sync="store.openingTimes" :switch-label="$t('customer.store.hasOpeningTimes')"/>
            <c3-form-text :label="$t('customer.openingTimesComment')" v-model="store.openingTimesComment" :validator="$v.store.openingTimesComment"
                          :maxLength="openingTimesCommentMaxLength"/>
            <c3-form-submit :label="$t('label.save')" :validator="$v" styling="action"/>
            <div v-if="$v.$error" class="c3-form-field-group-error-message">{{ $t('error.invalidUserData') }}</div>
        </c3-form>
        <div v-if="isRemovable" class="c3-store-wizard__remove">
            <c3-form-submit :label="$t('label.remove')" styling="danger" @click="remove"/>
        </div>
    </c3-profile-form-page>
</template>

<script>
    import phoneNumberService from '@/domain/service/phoneNumberService';
    import storeFactory from '@/domain/factory/store';

    import C3ProfileFormPage from '@/components/content/profile/FormPage';
    import C3CustomerOpeningTime from '@/components/form/fields/customer/OpeningTime';

    const FIELDS = [
        'address.street',
        'address.zip',
        'address.city',
        'address.country',
        'phone',
        'email',
        'hasOpeningTimes',
        'openingTimes',
        'openingTimesComment'
    ];

    export default {
        components: {
            C3ProfileFormPage,
            C3CustomerOpeningTime
        },
        data() {
            return {
                store: storeFactory.create(FIELDS),
                openingTimesCommentMaxLength: USER.OPENING_TIMES_COMMENT_LENGTH,
            }
        },
        computed: {
            countryOptions() {
                return this.$store.getters['staticContent/countrySelectOptions'];
            },
            subtitle() {
                if (!!this.$route.params.id) {
                    return this.$t('pages.customer.profile.stores.subtitleEdit', {address: this.store.address.street});
                }
                return this.$t('pages.customer.profile.stores.subtitleCreate');
            },
            isRemovable() {
                return !!this.$route.params.id && this.$store.getters['userStore/storeCountByUser'](this.currentUser.id) > 1;
            }
        },
        methods: {
            fillStoreFromVuex() {
                if (!this.$route.params.id) {
                    // User creates a new store, we can't prefill the fields from vuex in this case.
                    return;
                }

                let store = this.$store.getters['userStore/storeById'](this.$route.params.id);
                if (store === null) {
                    // Could not load store or store was deleted / ID doesn't exist anymore
                    this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message', () => this.$router.push({name: 'CustomerStoreList'}));
                    return;
                }
                storeFactory.fill(['id', ...FIELDS], this.store, store);
            },
            async submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                this.$store.commit('view/showLoading');

                let data = {...this.store};
                data.phone = phoneNumberService.sanitizeWithoutPrefix(data.phone);

                try {
                    if (!this.$route.params.id) {
                        await this.$store.dispatch('userStore/createStore', data);
                    } else {
                        await this.$store.dispatch('userStore/updateStore', data);
                    }
                    this.$c3.successAlert('success.dataUpdated.title', 'success.dataUpdated.message', () => this.$router.push({name: 'CustomerStoreList'}));
                } catch (e) {
                    this.$c3.handleErrorsWithAlert(e);
                    console.error('[BaseData] updateFields failed', e);
                }

                this.$store.commit('view/hideLoading')
            },
            remove() {
                this.$c3.confirmYesNo(
                    this.$t('confirm.store.deleteBranch.title'),
                    this.$t('confirm.store.deleteBranch.message'),
                    async () => {
                        this.$store.commit('view/showLoading');
                        try {
                            await this.$store.dispatch('userStore/deleteStore', this.store);
                        } catch (error) {
                            this.$c3.errorAlert('error.atLeastOneStoreRequired.title', 'error.atLeastOneStoreRequired.message');
                        }
                        this.$store.commit('view/hideLoading');
                        this.$router.push({name: 'CustomerStoreList'});
                    },
                    null,
                    {type: 'warning'}
                );
            }
        },
        async mounted() {
            this.$store.commit('view/showLoading');
            await Promise.all([
                this.$store.dispatch('user/loadCurrentUser'),
                this.$store.dispatch('userStore/loadStore'),
                this.$store.dispatch('staticContent/loadCountries')
            ]);

            this.fillStoreFromVuex();
            this.$store.commit('view/hideLoading')
        },
        validations() {
            return {
                store: storeFactory.createValidation(FIELDS),
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-store-wizard__remove {
        @include horizontalFormSpacing();

        .c3-form-field-group-submit {
            margin-top: 0;
        }
    }
</style>
