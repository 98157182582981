import {AbstractBaseFactory} from '@/domain/factory/abstractBaseFactory';
import {required, requiredIf, email, maxLength} from 'vuelidate/lib/validators'
import phoneNumberService from '@/domain/service/phoneNumberService';

export default {
    ...AbstractBaseFactory,
    create: (fields) => {
        let store = {
            id: null,
            address: {
                street: '',
                zip: '',
                city: '',
                country: null,
            },
            email: '',
            phone: '',
            hasOpeningTimes: true,
            openingTimes: null,
            openingTimesComment: '',
        };

        if (Array.isArray(fields) && fields.length) {
            return AbstractBaseFactory.fill(fields, {}, store);
        }

        return store;
    },
    createValidation: (fields) => {
        let validation = {
            id: {required},
            address: {
                street: {required},
                zip: {required},
                city: {required},
                country: {required},
            },
            email: {required, email},
            phone: {
                required,
                phoneNumber: phoneNumberService.validate
            },
            hasOpeningTimes: {},
            openingTimes: {
                required: requiredIf('hasOpeningTimes')
            },
            openingTimesComment: {
                maxLength: maxLength(USER.OPENING_TIMES_COMMENT_LENGTH)
            },
        };

        if (Array.isArray(fields) && fields.length) {
            return AbstractBaseFactory.fill(fields, {}, validation);
        }

        return validation;
    }
};
