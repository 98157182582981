<template>
    <div class="c3-form-field-opening-time-weekday">
        <div class="c3-form-field-opening-time-weekday-header">
            <div class="c3-form-field-opening-time-weekday-label">{{label}}</div>
            <c3-form-switch :value="isEnabledSwitch(0)" @input="val => updateSwitch(0,val)"/>
        </div>
        <div class="c3-form-field-opening-time-weekday-slider-list">
            <div class="c3-form-field-opening-time-weekday-slider-item">

                <template v-if="hasValidTimeRangeAtIndex(localValue, index)" v-for="(item,index) in localValue">
                    <div v-show="isEnabledSwitch(index)" class="c3-form-field-opening-time-weekday-slider">
                        <c3-form-time-range :start.sync="item.start" :end.sync="item.end"/>
                        <div v-if="MAX_ITEM_COUNT - 1 > index" class="c3-slider-icon-wrapper" @click="enableSwitch(index+1)">
                            <c3-icon-plus-circle v-show="!isEnabledSwitch((index+1))" primaryColor="senary"/>
                        </div>
                        <div v-else class="c3-slider-icon-wrapper" @click="disableSwitch(index)">
                            <c3-icon-times-circle primaryColor="tertiary"/>
                        </div>
                    </div>
                </template>

            </div>
        </div>

        <div v-show="!isEnabledSwitch(0)" class="c3-form-field-opening-time-weekday--closed">{{ $t('openingTimes.closed') }}</div>

        <div v-if="Array.isArray(previousDay) || null === previousDay" class="c3-form-field-opening-time-weekday--copy" @click="copyPreviousDay">
            <c3-icon-copy primaryColor="quinary"/>
            <span>{{ $t('openingTimes.usePreviousDayValue') }}</span>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import {C3Object} from '@/domain/utility/object';
    import {DateUtility} from '@/domain/utility/date';

    const MAX_ITEM_COUNT = 2;
    const MIN_MINUTES = 0;
    const MAX_MINUTES = 1440;
    const DEFAULT_START = 540;
    const DEFAULT_END = 1020;
    const DEFAULT_VALUE_ACTIVE = {isActive: true, start: DEFAULT_START, end: DEFAULT_END};
    const DEFAULT_VALUE_INACTIVE = {isActive: false, start: DEFAULT_START, end: DEFAULT_END};

    export default {
        components: {},
        props: {
            value: {
                validator: prop => Array.isArray(prop) || prop === null,
                required: true
            },
            label: {
                type: String
            },
            previousDay: {
                validator: prop => Array.isArray(prop) || prop === null,
            },
        },
        data() {
            return {
                MAX_ITEM_COUNT: MAX_ITEM_COUNT,
                localValue: [
                    Object.assign({}, DEFAULT_VALUE_INACTIVE)
                ]
            }
        },
        computed: {},
        methods: {
            hasValidTimeRangeAtIndex(value, index) {
                if (!Array.isArray(value) || value.length - 1 < index) {
                    return false;
                }
                return undefined !== value[index].isActive && undefined !== value[index].start && undefined !== value[index].end;
            },
            copyPreviousDay() {
                this.$emit('input', C3Object.deepClone(this.previousDay));
            },
            convertTimeRangeDateEntryToMinutes(entry) {
                return {
                    start: DateUtility.convertDateToMinutes(entry.start),
                    end: DateUtility.convertDateToMinutes(entry.end)
                };
            },
            updateSwitch(index, value) {
                this.localValue[index].isActive = value;
            },
            enableSwitch(index) {
                if (!this.hasValidTimeRangeAtIndex(this.localValue, index)) {
                    this.$set(this.localValue, index, Object.assign({}, DEFAULT_VALUE_ACTIVE));
                }
                if (index > 0 && this.localValue[index - 1].end >= this.localValue[index].start) {
                    this.localValue[index].start = this.localValue[index - 1].end + 1;
                }

                if (this.localValue[index].start > this.localValue[index].end) {
                    this.localValue[index].end = this.localValue[index].start + 1;
                }

                this.updateSwitch(index, true);
            },
            disableSwitch(index) {
                this.updateSwitch(index, false);
            },
            isEnabledSwitch(index) {
                try {
                    // Check isActive on all previous and the current switch
                    for (let i = index; 0 <= i; i--) {
                        if (!this.localValue[i].isActive) {
                            return false;
                        }
                    }
                    return true;
                } catch (e) {
                    return false;
                }
            },
            compareOpeningTimeEntryCollections(col1, col2) {
                if (Array.isArray(col1) && Array.isArray(col2)) {
                    if (col1.length !== col2.length) {
                        return false;
                    }

                    for (let i = 0; i < col1.length; i++) {
                        if (moment(col1[i].start).format('hh:mm') !== moment(col2[i].start).format('hh:mm')) {
                            return false;
                        }

                        if (moment(col1[i].end).format('hh:mm') !== moment(col2[i].end).format('hh:mm')) {
                            return false;
                        }
                    }

                    return true;
                }

                return col1 === col2;
            },
            update() {
                let elements = [];
                let prevValue = null;
                for (let i = 0; i < this.localValue.length; i++) {
                    if (!this.localValue[i].isActive || !this.hasValidTimeRangeAtIndex(this.localValue, i)) {
                        // Ignore following values
                        break;
                    }

                    let value = {start: this.localValue[i].start, end: this.localValue[i].end};

                    if (typeof value.start !== 'string' && typeof value.start !== 'number') {
                        value.start = MIN_MINUTES;
                    }
                    value.start = DateUtility.convertMinutesToDate(value.start);

                    if (typeof value.end !== 'string' && typeof value.end !== 'number') {
                        value.end = MAX_MINUTES;
                    }
                    value.end = DateUtility.convertMinutesToDate(value.end);

                    elements.push(value);
                    prevValue = value;
                }

                // Check if this.value and elements are equal before avoid to avoid endless update recursion
                if (!this.compareOpeningTimeEntryCollections(this.value, elements)) {
                    this.$emit('input', elements);
                }
            },
            updateLocalFromValue() {
                if (!Array.isArray(this.value) || 0 === this.value.length) {
                    // Disable values, but do not delete them.
                    // This is useful so the values are still there, if the user enables the switch again
                    this.localValue[0].isActive = false;
                    return;
                }

                this.value.forEach((item, index) => {
                    item = this.convertTimeRangeDateEntryToMinutes(item);
                    item.isActive = true;
                    if (!this.hasValidTimeRangeAtIndex([item], 0)) {
                        return;
                    }
                    this.$set(this.localValue, index, item);
                });
            }
        },
        watch: {
            localValue: {
                handler: function () {
                    this.update();
                },
                deep: true
            },
            value: {
                handler: function () {
                    this.updateLocalFromValue();
                },
                deep: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    .c3-form-field-opening-time-weekday {
        padding-top: 25px;
        padding-bottom: 25px;
        border-bottom: 2px solid $white-color;

    }

    .c3-form-field-opening-time-weekday-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .c3-form-field-group {
            margin-top: 0;
        }
    }

    .c3-form-field-opening-time-weekday-label {
        @include openSansBold();
        font-size: 16px;
    }

    .c3-form-field-opening-time-weekday-slider {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .c3-form-field-group-time-range {
            flex: 1;
            margin-top: 0;
            overflow: hidden;
        }

        .c3-icon-wrapper {
            height: 22px;
            padding-left: 20px;
        }
    }

    .c3-slider-icon-wrapper {
        height: 22px;
        width: 42px;
    }

    .c3-form-field-opening-time-weekday--closed {
        color: $tertiary-color;
        font-size: 14px;
    }

    .c3-form-field-opening-time-weekday--copy {
        display: block;
        margin-top: 15px;
        @include openSansRegular();
        font-size: 14px;

        .c3-icon-wrapper {
            height: 20px;
            margin-right: 10px;
        }
    }
</style>
