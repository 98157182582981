<template>
    <div class="c3-main">
        <div class="c3-page-content">
            <c3-title-with-subtitle :title="$t('pages.customer.profile.stores.title')" :subtitle="$t('pages.customer.profile.stores.subtitleList')"/>
            <div class="c3-customer-store-list">
                <div class="c3-customer-store-list__body">
                    <template v-for="item in items">
                        <router-link :key="item.id" :to="{name: 'CustomerStoreWizardBaseData', params: {id: item.id}}" class="c3-customer-store-list__item">
                            <div class="c3-customer-store-list__item-content">
                                <div class="c3-customer-store-list__item-title">{{ item.address.street }}</div>
                                <div class="c3-customer-store-list__item-text">{{ item.address.zip }} {{ item.address.city }}</div>
                            </div>
                            <div class="c3-customer-store-list__item-action">
                                <c3-icon-edit primaryColor="white"/>
                            </div>
                        </router-link>
                    </template>
                </div>
                <router-link :to="{name: 'CustomerStoreWizard'}">
                    <c3-button :label="$t('pages.customer.profile.stores.button')" styling="4" class="c3-customer-store-list__add"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import C3Button from '@/components/form/Button.vue';
    import C3TitleWithSubtitle from '@/components/content/text/TitleWithSubtitle';

    export default {
        components: {C3Button, C3TitleWithSubtitle},
        data() {
            return {}
        },
        computed: {
            items() {
                return this.$store.getters['userStore/currentUserStoreListOrderByZip'];
            },
        },
        async created() {
            this.$store.commit('view/showLoading');
            try {
                await Promise.all([
                    this.$store.dispatch('user/loadCurrentUser'),
                    this.$store.dispatch('userStore/loadStore', this.currentUser.id)
                ]);
            } catch (e) {
                console.error(e);
            }
            this.$store.commit('view/hideLoading');
        }
    };
</script>

<style lang="scss" scoped>
    .c3-main {
        background-color: $background-color-alternative-section;
    }

    .c3-title-with-subtitle {
        padding: var(--spacing-m) var(--spacing-m) 0 var(--spacing-m);
    }

    .c3-customer-store-list {
        margin-top: var(--spacing-m);
        @include horizontalSpacing();
        overflow: hidden;
    }

    .c3-customer-store-list__body {
        margin-top: calc(-1 * var(--grid-gutter));
        margin-left: calc(-1 * var(--grid-gutter));
        overflow: hidden;
    }

    .c3-customer-store-list__item {
        display: flex;
        margin-top: var(--grid-gutter);
        margin-left: var(--grid-gutter);
        padding: var(--spacing-xxxs) var(--spacing-xs);
        background-color: $quinary-color;
        @include boxShadow();
        border-radius: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .c3-customer-store-list__item-title {
        @include openSansBold();
        font-size: 14px;
        color: $white-color;
    }

    .c3-customer-store-list__item-text {
        font-size: 12px;
        color: $white-color;
    }

    .c3-customer-store-list__item-action {
        height: 20px;
        margin-left: auto;
        align-self: center;
    }

    .c3-customer-store-list__add {
        margin-top: var(--spacing-m);
    }
</style>
